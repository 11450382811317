import type {AdminApiSessionProps} from "~/stCommon/api/admin/model/AdminApiSessionProps";
import {MyProjectConstants} from "~/projectCommon/MyProjectConstants";

/**
 * クッキーにセットする管理用のセッション
 */
class MyAdminSession {
  email: string
  apiAccessToken: string

  constructor(props?: AdminApiSessionProps) {
    this.email = props?.email || ''
    this.apiAccessToken = props?.apiAccessToken || ''
  }

  static get cookieName(): string {
    return MyProjectConstants.cookies.adminSession.name
  }

  toString(): string {
    return JSON.stringify({
      email: this.email,
      apiAccessToken: this.apiAccessToken,
    })
  }

  static fromCookie(): MyAdminSession {
    // クッキーの値がJSONの場合、自動でパースしてくれる。デフォルトの挙動。
    // https://nuxt.com/docs/api/composables/use-cookie#encode
    let cookie = useCookie<any>(this.cookieName)
    let session = new MyAdminSession()
    session.email = cookie.value?.email as string || ''
    session.apiAccessToken = cookie.value?.apiAccessToken as string || ''
    return session
  }

  isLoggedIn(): boolean {
    return !!this.email && this.email.length > 0 &&
      !!this.apiAccessToken && this.apiAccessToken.length > 0
  }

  save() {
    const cookie = useCookie(
      MyAdminSession.cookieName,
      {
        secure: true,
        sameSite: 'strict',
      },
    )
    cookie.value = this.toString()
  }

  clear() {
    const cookie = useCookie(MyAdminSession.cookieName)
    cookie.value = null
  }
}

export function useMyAdminSession(props?: AdminApiSessionProps) {
  if (props) {
    return new MyAdminSession(props)
  } else {
    return MyAdminSession.fromCookie()
  }
}
